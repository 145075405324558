import React from 'react';
import PropTypes from 'prop-types';
import { TitleLink } from './navLink.styled';

const NavLink = ({ title, to, className }) => (
  <TitleLink to={to} className={className} activeClassName="current-page">
    {title}
  </TitleLink>
);

NavLink.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NavLink;
