import styled from 'styled-components';
import { Link } from 'gatsby';
import { widthFrom, widthUntil } from '@theme/theme';

const Header = styled.header`
  background-color: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const TitleLink = styled(Link).attrs((props) => ({
  className: props.className,
}))`
  color: ${({ theme }) => theme.colors.themeColor};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSizes.heading};
  line-height: ${({ theme }) => theme.lineHeight.subHeading};
  text-decoration: none;
  &:hover {
    box-shadow: none;
  }
  ${widthUntil.MOBILE`
      font-size: ${({ theme }) => theme.fontSizes.mobileHeading};
  `}
`;

const NavContainer = styled.nav`
  margin-top: 21px;
`;

const NavLink = styled(Link)`
  display: inline;
  color: ${({ theme }) => theme.colors.themeColor};
  text-decoration: none;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;

  &.current-page {
    border-bottom: 2px solid ${({ theme }) => theme.colors.accentYellow};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.accentYellow};
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${widthUntil.TABLET`
    color: ${({ theme }) => theme.colors.gray};
    &.current-page {
      border-bottom: none;
    }
  `}
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  ${widthUntil.TABLET`
    flex-flow: column nowrap;
    background-color: ${({ theme }) => theme.colors.themeColor};
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  `}
`;

const ListItem = styled.li`
  padding: 18px 10px;
  &:nth-child(1) {
    display: none;
  }
  ${widthUntil.MOBILE`
      color: #fff;
      &:nth-child(1) {
        display: unset;
      }
  `}
`;

export { TitleLink, NavContainer, NavLink, Header, List, ListItem };
