import styled from 'styled-components';
import { Link } from 'gatsby';

const TitleLink = styled(Link).attrs((props) => ({
  className: props.className
    ? props.className
    : 'text-lg leading-6 font-medium text-gray-500 hover:text-darkBlue focus:outline-none focus:text-darkBlue transition ease-in-out duration-150',
}))`
  &:hover {
    box-shadow: none;
  }

  &.current-page {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export { TitleLink };
