import { graphql, useStaticQuery } from 'gatsby';

export const useNavlinks = () => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      navigationJson {
        headerLinks {
          name
          link
        }
      }
    }
  `);

  const navigationLinks = data.navigationJson.headerLinks;

  return navigationLinks;
};

export default useNavlinks;
