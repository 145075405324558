import React, { useState } from 'react';
import useNavLinksMetadata from '@hooks/use-navlinks';
import Container from '@containers/Container';
import NavLink from '@components/navLink';
import { Transition } from '@tailwindui/react';
import Icon from '@components/ui/Icons';
import Logo from '@components/ui/Logo';
import { DARK_BLUE_LANDSCAPE } from '@constants';
import { TitleLink, Header } from './topNav.styled';

const TopNav = () => {
  const navlinks = useNavLinksMetadata();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Header className="relative bg-white">
      <div className="bg-white overflow-hidden">
        <div className="max-w-screen-xl mx-auto px-4 lg:pl-0 lg:px-8 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div>
              <TitleLink to="/" className="flex">
                <Logo
                  className="h-8 w-auto sm:h-10"
                  logo={DARK_BLUE_LANDSCAPE}
                />
              </TitleLink>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                onClick={() => setIsOpen(!isOpen)}
              >
                <Icon name="menu" className="text-darkBlue" />
              </button>
            </div>
            <div className="hidden md:flex-1 md:flex md:items-center md:justify-between md:space-x-12">
              <div className="flex space-x-10" />
              <div className="flex items-center space-x-8">
                <nav className="flex space-x-10">
                  {navlinks.map((nav, key) => (
                    <NavLink key={key} title={nav.name} to={nav.link} />
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <Transition
            show={isOpen}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="z-20 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-lg">
                <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5 space-y-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <Logo
                          className="h-8 w-auto"
                          logo={DARK_BLUE_LANDSCAPE}
                        />
                      </div>
                      <div className="-mr-2">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <Icon name="close" className="text-darkBlue" />
                        </button>
                      </div>
                    </div>
                    <div>
                      <nav className="grid gap-6">
                        {navlinks.map((nav, key) => (
                          <NavLink key={key} title={nav.name} to={nav.link} />
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Header>
  );
};

TopNav.propTypes = {};

TopNav.defaultProps = {
  title: '',
};

export default TopNav;
