import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from './Container.styled';

const Container = ({
  children,
  height,
  isRow,
  margin,
  mobileIsColumn,
  mobileIsColumnReverse,
  alignItems,
}) => (
  <StyledContainer
    height={height}
    alignItems={alignItems}
    isRow={isRow}
    margin={margin}
    mobileIsColumn={mobileIsColumn}
    mobileIsColumnReverse={mobileIsColumnReverse}
  >
    {children}
  </StyledContainer>
);

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  height: PropTypes.number,
  margin: PropTypes.string,
  alignItems: PropTypes.string,
  isRow: PropTypes.bool,
  mobileIsColumn: PropTypes.bool,
  mobileIsColumnReverse: PropTypes.bool,
};

export default Container;
