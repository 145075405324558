import styled from 'styled-components';
import { widthFrom, widthUntil } from '@theme/theme';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  justify-content: center;
  align-items: ${({ alignItems }) => alignItems || 'center'};;
  margin: ${({ margin }) => margin || '0 auto'};
  flex-shrink: 0;
  padding: 0 5%;
  box-sizing: border-box;

  ${widthFrom.TABLET`
    padding: 0 3%;
  `}

  ${widthFrom.SCREEN`
    max-width: 1280px;
  `}

  ${widthUntil.MOBILE`
      flex-direction: ${({ mobileIsColumnReverse }) =>
        mobileIsColumnReverse ? 'column-reverse' : 'column'};
  `}
`;

export { StyledContainer };
