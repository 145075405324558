import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              twitterUsername
              linkedin
              instagram
              github
              youtube
              dribble
            }
            title
            description
            author
            copyright
            image
            siteUrl
          }
        }
      }
    `
  );
  return data.site.siteMetadata;
};

export default useSiteMetadata;
