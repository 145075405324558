export const YELLOW_PORTRAIT =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992226/LOGOS/yellow_por_gkn7jo.svg';
export const YELLOW_LANDSCAPE =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992225/LOGOS/yellow_ls_uw2ani.svg';
export const YELLOW_SOLO =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992225/LOGOS/yellow_sym_jueoaw.svg';

export const WHITE_SOLO =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992225/LOGOS/white_sym_jjg0ap.svg';
export const WHITE_LANDSCAPE =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992223/LOGOS/white_ls_kbh5wm.svg';
export const WHITE_PORTRAIT =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992225/LOGOS/white_por_aihdqj.svg';

export const TEAL_SOLO =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992223/LOGOS/teal_sym_xhgwvv.svg';
export const TEAL_LANDSCAPE =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992223/LOGOS/teal_ls_exdt4t.svg';
export const TEAL_PORTRAIT =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992223/LOGOS/teal_por_dsvjda.svg';

export const DARK_BLUE_SOLO =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992223/LOGOS/navy_sym_wzvapl.svg';
export const DARK_BLUE_LANDSCAPE =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992221/LOGOS/navy_ls_bdq6fb.svg';
export const DARK_BLUE_PORTRAIT =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992222/LOGOS/navy_por_wdpono.svg';

export const BLACK_SOLO =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992220/LOGOS/black_sym_e3tfni.svg';
export const BLACK_LANDSCAPE =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992221/LOGOS/black_ls_vwwhnt.svg';
export const BLACK_PORTRAIT =
  'https://res.cloudinary.com/steven-sajja/image/upload/v1598992221/LOGOS/black_por_nze0rv.svg';
