import styled from 'styled-components';
import { StyledIconBase } from '@styled-icons/styled-icon';

export const FooterSection = styled.footer`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.lightBackground};
  display: flex;
  padding: 26px 0;
`;

export const IconStyleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${StyledIconBase} {
    height: 24px;
    width: 24px;
    color: #000000;
  }
`;

export const CopyRight = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.themeColor};
  font-size: ${({ theme }) => theme.fontSizes.hero};
  line-height: ${({ theme }) => theme.lineHeight.medium};
`;
