import React from 'react';
import useSiteMetadata from '@hooks/use-sitemetadata';
import Container from '@containers/Container';
import {
  Linkedin,
  Twitter,
  Instagram,
  Github,
  Youtube,
  Dribbble,
} from '@styled-icons/boxicons-logos';
import { FooterSection, IconStyleWrapper, CopyRight } from './footer.styled';
import SocialMediaLinks from './socialMediaLinks';

export const Footer = () => {
  const currentDay = new Date();
  const currentYear = currentDay.getFullYear();

  const footerMetadata = useSiteMetadata();
  const {
    linkedin,
    twitter,
    instagram,
    github,
    youtube,
    dribble,
  } = footerMetadata.social;

  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center md:order-2">
          <SocialMediaLinks
            className="ml-4 text-gray-400 hover:text-darkBlue"
            to={linkedin}
            name="Linkedin"
            icon={<Linkedin className="h-6 w-6" />}
          />
          <SocialMediaLinks
            className="ml-4 text-gray-400 hover:text-darkBlue"
            to={twitter}
            name="Twitter"
            icon={<Twitter className="h-6 w-6" />}
          />
          <SocialMediaLinks
            className="ml-4 text-gray-400 hover:text-darkBlue"
            to={youtube}
            name="YouTube"
            icon={<Youtube className="h-6 w-6" />}
          />
          <SocialMediaLinks
            className="ml-4 text-gray-400 hover:text-darkBlue"
            to={instagram}
            name="Instagram"
            icon={<Instagram className="h-6 w-6" />}
          />
          <SocialMediaLinks
            className="ml-4 text-gray-400 hover:text-darkBlue"
            to={github}
            name="Github"
            icon={<Github className="h-6 w-6" />}
          />
          <SocialMediaLinks
            className="ml-4 text-gray-400 hover:text-darkBlue"
            to={dribble}
            name="Dribble"
            icon={<Dribbble className="h-6 w-6" />}
          />
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base leading-6 text-gray-400">
            {`© ${currentYear} — ${footerMetadata.copyright}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
