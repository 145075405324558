import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset-advanced';

export default createGlobalStyle`
  ${reset};
  *, *::after, *::before {
      box-sizing: border-box;
    }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body {
      height: 100%;
  }

  body {
  cursor: default;
  line-height: ${(props) => props.theme.lineHeights.text};
  font-size: ${(props) => props.theme.fontSizes.text};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeight.text};
  visibility: visible;
  font-family: ${(props) => props.theme.fontFamily.inter};
  color: ${(props) => props.theme.colors.textColor};
  position: relative;
  background-color: ${(props) => props.theme.colors.bodyBgColor};
  &.no-overflow{
    overflow: hidden;
  }
  }
  svg {
    width: 100%;
    height: auto;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${(props) => props.theme.colors.headingColor};
    font-family: ${(props) => props.theme.fontFamily.ibm};
    font-weight: ${(props) => props.theme.fontWeight.heading};
    margin-top: 0;
    margin-bottom: 0;
    line-height: ${(props) => props.theme.lineHeights.heading};
    transition: ${(props) => props.theme.transition};
}
h1 {
    font-size: ${(props) => props.theme.fontSizes.h1};
    @media only screen and (max-width: 1199px){
      font-size: 46px;
    }
    @media only screen and (max-width: 991px){
      font-size: 40px;
    }
    @media only screen and (max-width: 767px){
      font-size: 34px;
    }
  }
h2 {
    font-size: ${(props) => props.theme.fontSizes.h2};
    @media only screen and (max-width: 1199px){
      font-size: 36px;
    }
    @media only screen and (max-width: 991px){
      font-size: 36px;
    }
    @media only screen and (max-width: 767px){
      font-size: 30px;
    }
  }
h3 {
    font-size: ${(props) => props.theme.fontSizes.h3};
    @media only screen and (max-width: 1199px){
      font-size: 32px;
    }
    @media only screen and (max-width: 991px){
      font-size: 28px;
    }
    @media only screen and (max-width: 767px){
      font-size: 25px;
    }
}
h4 {
    font-size: ${(props) => props.theme.fontSizes.h4};
    @media only screen and (max-width: 1199px){
      font-size: 30px;
    }
    @media only screen and (max-width: 991px){
      font-size: 28px;
    }
    @media only screen and (max-width: 767px){
      font-size: 24px;
    }
}
h5 {
    font-size: ${(props) => props.theme.fontSizes.h5};
    @media only screen and (max-width: 767px){
      font-size: 20px;
    }
}
h6 {
    font-size: 18px;
}



p:last-child {
    margin-bottom: 0;
}

  a {
    cursor: pointer !important;
  }
  a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
}
a, button, img, input, span {
  transition: ${(props) => props.theme.transition};
}
a {
  strong {
  font-weight:${(props) => props.theme.fontWeight.semiBold};
  color:inherit;
  }
}
*:focus {
  outline: none !important;
}
a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}
.btn.focus, .btn:focus {
    outline: none;
    box-shadow: none;
}
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.colors.darkBlue};
  box-shadow: inset 0 -30px 0 0 ${({ theme }) => theme.colors.accentYellow};
}

  img{
    max-width: 100%;
  }
  button, input[type="submit"] {
  cursor: pointer;
}
ul {
  list-style-position: inside;
  list-style-type: "-->";
  margin: 0;
  padding: 0;
}
.mark, mark {
    padding: 0 0;
    background-color: transparent;
}

strong {
  font-weight:${(props) => props.theme.fontWeight.semiBold};
  color:#2D3748;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

`;
