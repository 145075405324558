import React from 'react';
import TopNav from '@src/components/topNav';

import { ThemeProvider } from 'styled-components';
import { StyledMain } from "./PageLayout.styled";
import GlobalStyle from '@theme/GlobalStyle';
import { theme } from '@theme/theme';
import Footer from '@components/footer/Footer';
import SEO from '@components/seo';
import Transition from '@components/transition';
import ScrollToTop from '@components/ui/scroll-to-top';
// import '@assets/css/bootstrap.css';
import '@assets/css/tailwind.css';

const PageLayout = props => {
  const { children, title, hideHeader, description, location } = props;
  return (
    <ThemeProvider theme={theme}>
        <SEO title={title} description={description} />
      {/* <div className="wrapper"> */}
      <div>
        <GlobalStyle />
        {hideHeader ? null : <TopNav />}
        {children}
        <Footer />
        {/* <ScrollToTop /> */}
      </div>
    </ThemeProvider>
  );
};

export default PageLayout;
