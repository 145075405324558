import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LogoContainer = styled.img.attrs((props) => ({
  className: props.className ? props.className : 'h-8 w-auto sm:h-10',
}))``;

const Logo = ({ logo, className }) => (
  <LogoContainer alt="Sajja logo" className={className} src={logo} />
);

Logo.propTypes = {
  logo: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Logo;
