import { keys, reduce } from 'lodash';
import { css } from 'styled-components';

const theme = {
  colors: {
    darkBlue: '#00283C',
    'darkBlue-100': '#e6eaec',
    'darkBlue-200': '#ccd4d8',
    'darkBlue-300': '#b3bfc5',
    'darkBlue-400': '#99a9b1',
    'darkBlue-500': '#80949e',
    'darkBlue-600': '#667e8a',
    'darkBlue-700': '#4d6977',
    'darkBlue-800': '#335363',
    'darkBlue-900': '#1a3e50',
    teal: '#00A2AA',
    'teal-100': '#e6f6f7',
    'teal-200': '#ccecee',
    'teal-300': '#b3e3e6',
    'teal-400': '#99dadd',
    'teal-500': '#80d1d5',
    'teal-600': '#66c7cc',
    'teal-700': '#4dbec4',
    'teal-800': '#33b5bb',
    'teal-900': '#1aabb3',
    accentYellow: '#FFC043',
    'accentYellow-100': '#fff9ec',
    'accentYellow-200': '#fff2d9',
    'accentYellow-300': '#ffecc7',
    'accentYellow-400': '#ffe6b4',
    'accentYellow-500': '#ffe0a1',
    'accentYellow-600': '#ffd98e',
    'accentYellow-700': '#ffd37b',
    'accentYellow-800': '#ffcd69',
    'accentYellow-900': '#ffc656',
    black: '#000000',
    white: '#ffffff',
    themeColor: '#00283C',
    themeColorBlack: '#333333',
    themeColorTeal: '#00A2AA',
    themeColorYellow: '#FFC043',
    textColor: '#696969',
    headingColor: '#333333',
    headingColorTwo: '#d2a98e',
    headingColorDefault: '#086AD8',
    subHeadingColor: '#6d70a6',
    borderColor: '#ededed',
    borderColorTwo: '#f8f8f8',
    borderDash: '#dddddd',
    primary: '#004395',
    secondary: '#d2a98e',
    success: '#28a745',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#dc3545',
    light: '#f8f9fa',
    bodyBgColor: '#ffffff',
    gray: '#F8F8F8',
    grayTwo: '#F6FAFE',
    grayThree: '#f6f2ed',
    silver: '#ababab',
    silverTwo: '#cccccc',
    kimberly: '#6d70a6',
    spring: '#F6F2ED',
    pattens: '#def0ff',
    yellow: '#ffb944',
    whiteSmoke: '#f5f5f5',
  },
  lineHeights: {
    text: 1.75,
    heading: 1.41,
  },
  fontSizes: {
    small: '12px',
    body: '16px',
    biggerBody: '18px',
    heading: '50px',
    mobileHeading: '35px',
    subHeading: '20px',
    hero: '24px',
    text: '16px',
    h1: '56px',
    h2: '48px',
    h3: '40px',
    h4: '34px',
    h5: '24px',
    h6: '18px',
  },
  lineHeight: {
    small: '15px',
    regular: '20px',
    medium: '25px',
    heading: '61px',
    subHeading: '50px',
  },
  fontWeight: {
    ultraLight: 100,
    light: 200,
    book: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    heavy: 700,
    bold: 800,
    ultraBold: 900,
    text: 400,
    heading: 700,
  },
  fontFamily: {
    text: 'roc-grotesk',
    heading: 'TiemposFine',
    inter: 'Inter',
    ibm: 'IBM Plex Sans',
  },
  transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
};

/**
 * Declare Device Sizes
 */
const sizes = {
  SCREEN: 1599,
  DESKTOP: 1366,
  LAPTOP: 1024,
  TABLET_LARGE: 990,
  TABLET: 768,
  MOBILE: 480,
  xxlarge: '1699px',
  xlarge: '1599px',
  large: '1199px',
  medium: '991px',
  small: '767px',
  xsmall: '575px',
  xxsmall: '480px',
  xxlargeOnly: '1700px',
  xlargeOnly: '1600px',
  largeOnly: '1200px',
  mediumOnly: '992px',
  smallOnly: '768px',
  xsmallOnly: '576px',
  xxsmallOnly: '481px',
};

export const device = {
  xxlarge: `(max-width: ${sizes.xxlarge})`,
  xlarge: `(max-width: ${sizes.xlarge})`,
  large: `(max-width: ${sizes.large})`,
  medium: `(max-width: ${sizes.medium})`,
  small: `(max-width: ${sizes.small})`,
  xsmall: `(max-width: ${sizes.xsmall})`,
  xxsmall: `(max-width: ${sizes.xxsmall})`,
  xxlargeOnly: `(min-width: ${sizes.xxlargeOnly})`,
  xlargeOnly: `(min-width: ${sizes.xlargeOnly})`,
  largeOnly: `(min-width: ${sizes.largeOnly})`,
  mediumOnly: `(min-width: ${sizes.mediumOnly})`,
  smallOnly: `(min-width: ${sizes.smallOnly})`,
  xsmallOnly: `(min-width: ${sizes.xsmallOnly})`,
  xxsmallOnly: `(min-width: ${sizes.xxsmallOnly})`,
};

const sizeKeys = keys(sizes);

/**
 * Media Query
 * Mobile First Methodology
 */
const widthFrom = reduce(
  sizeKeys,
  (accumulator, label) => {
    const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `;
    return accumulator;
  },
  {}
);

/**
 * Media Query
 * Desktop First Methodology
 */
const widthUntil = reduce(
  sizeKeys,
  (accumulator, label) => {
    const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)}
      }
    `;
    return accumulator;
  },
  {}
);

export { widthFrom, widthUntil, theme };
