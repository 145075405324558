import styled from 'styled-components';

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;
// margin: 2rem auto;
// max-width: 90vw;
// width: 550px;

export { StyledMain };
