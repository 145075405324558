import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SMLink = styled.a`
  &:hover {
    box-shadow: none;
  }
`;

const SocialMediaLink = ({ to, icon, name, className }) => (
  <SMLink
    href={to}
    target="_blank"
    rel=" noopener noreferrer"
    className={className}
  >
    <span className="sr-only">{name}</span>
    {icon}
  </SMLink>
);

SocialMediaLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  className: PropTypes.node.isRequired,
};

export default SocialMediaLink;
